import React, { FC, useEffect, useState } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { m, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Title from '../../../../components/Title';
import Paragraph from '../../../../components/Paragraph';
import ButtonBlue from '../../../../components/Buttons/ButtonBlue';
import { StyledImage, Text, Wrapper } from './styled';
import { slideInLeft, slideInRight } from '../../../../data/animations';

interface Props {
  product?: {
    title?: string | null;
    description?: string | null;
    link?: {
      text?: string | null;
      url?: string | null;
    } | null;
    image?: {
      childImageSharp?: {
        gatsbyImageData: IGatsbyImageData;
      } | null;
    } | null;
  } | null;
}

const Single: FC<Props> = ({ product }) => {
  const { ref, inView } = useInView();
  const control = useAnimation();
  const [viewed, steViewed] = useState(false);

  useEffect(() => {
    if (inView) {
      control.start('visible');
      steViewed(true);
    } else {
      if (viewed) return;
      control.start('hidden');
    }
  }, [control, inView, viewed]);
  return (
    <Wrapper ref={ref}>
      {product?.image?.childImageSharp?.gatsbyImageData && (
        <m.div variants={slideInLeft} initial="hidden" animate={control}>
          <StyledImage
            image={product?.image?.childImageSharp?.gatsbyImageData}
            alt={product?.title || ''}
            objectPosition="bottom center"
            objectFit="cover"
            imgStyle={{ width: 'auto', height: '100%', margin: 'auto' }}
          />
        </m.div>
      )}
      <Text variants={slideInRight} initial="hidden" animate={control}>
        <Title>{product?.title}</Title>
        <Paragraph>{product?.description}</Paragraph>
        {product?.link && (
          <ButtonBlue
            href={product?.link?.url || ''}
            target="_blank"
            rel="noreferrer"
          >
            {product?.link?.text}
          </ButtonBlue>
        )}
      </Text>
    </Wrapper>
  );
};

export default Single;

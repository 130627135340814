import { m } from 'framer-motion';
import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Content = styled.div`
  display: grid;
  align-items: flex-start;

  gap: 24px;

  ${media.sm`
    grid-template-columns: repeat(2, 1fr);
  `}

  overflow: hidden;
`;

export const StoryBody = styled(m.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;

  background: rgba(209, 233, 255, 0.02);
  box-shadow:
    0px 14px 29px 0px rgba(255, 255, 255, 0.03) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 54px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(32px);

  gap: 8px;
  padding: 24px 16px 0;

  ${media.sm`
    gap: 16px;
    padding: 32px 24px 0;
  `}

  ${media.md`
    gap: 24px;
    padding: 50px 40px 0;
  `}
`;

export const StoryTitle = styled.div`
  color: #fefefe;
  font-style: normal;
  font-weight: 800;

  font-size: 12px;
  line-height: 12.238px;

  ${media.sm`
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
  `}

  ${media.md`
    font-size: 24px;
    line-height: 26.4px;
  `}
`;

export const StoryText = styled.div`
  color: #a9b7cd;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12.238px;

  ${media.sm`
    font-size: 12px;
    line-height: 26.4px;
  `}
  & > b {
    color: #fff;
  }
`;

import styled from 'styled-components';
import { media } from '../Layout/breakpoints';

const Title = styled.h1`
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin: 0;

  ${media.md`
    font-size: 36px;
  `}
`;

export default Title;

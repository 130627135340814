import { m } from 'framer-motion';
import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Content = styled.div`
  display: grid;
  align-items: center;

  ${media.sm`
    grid-template-columns: 2fr 3fr;
    `}

  ${media.md`
    grid-template-columns: 580px 1fr;
  `}
`;

export const Text = styled(m.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.md`
    gap: 32px;
  `}
`;

export const Link = styled.a`
  color: #0085ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 156%;
  text-decoration-line: underline;
`;

import { css } from 'styled-components';
import { Interpolation, Styles } from 'styled-components/dist/types';

const Sizes = {
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920,
};
type MediaType = Record<
  keyof typeof Sizes,
  (
    first: Styles<object>,
    ...interpolations: Interpolation<object>[]
  ) => TemplateStringsArray
>;

const breakpoints: Record<keyof typeof Sizes, number> = {
  sm: Sizes.sm,
  md: Sizes.md,
  lg: Sizes.lg,
  xl: Sizes.xl,
};

export const media = Object.keys(breakpoints).reduce(
  (acc: MediaType, label) => {
    return {
      ...acc,
      [label as keyof typeof Sizes]: (
        first: Styles<object>,
        ...interpolations: Interpolation<object>[]
      ) => css`
        @media (min-width: ${breakpoints[label as keyof typeof Sizes] / 16}em) {
          ${css(first, ...interpolations)}
        }
      `,
    };
  },
  {} as MediaType,
);

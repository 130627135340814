import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ButtonsWrapper, Header } from './styled';
import ButtonBlue from '../../../components/Buttons/ButtonBlue';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import useMediaImagesSet from '../../../helpers/useMediaImagesSet';

const Hero: FC<Queries.HeroFragment> = ({ hero }) => {
  const images = useMediaImagesSet(hero?.background);

  return (
    <Header>
      {images && (
        <GatsbyImage
          image={images}
          alt="Background"
          loading="eager"
          style={{ minHeight: 585 }}
        />
      )}
      <ButtonsWrapper>
        <ButtonBlue
          href={hero?.links?.main?.url || ''}
          target="_blank"
          rel="noreferrer"
        >
          {hero?.links?.main?.text}
        </ButtonBlue>
        <ButtonWhite
          href={hero?.links?.secondary?.url || ''}
          target="_blank"
          rel="noreferrer"
        >
          {hero?.links?.secondary?.text}
        </ButtonWhite>
      </ButtonsWrapper>
    </Header>
  );
};

export const query = graphql`
  fragment Hero on Json {
    hero {
      links {
        main {
          text
          url
        }
        secondary {
          text
          url
        }
      }
      background {
        large {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100, layout: FIXED)
          }
        }
        medium {
          childImageSharp {
            gatsbyImageData(width: 1024, layout: FIXED, quality: 100)
          }
        }
        small {
          childImageSharp {
            gatsbyImageData(width: 450, layout: FIXED, quality: 100)
          }
        }
      }
    }
  }
`;

export default Hero;

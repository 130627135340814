import React, { FC } from 'react';

import ExternalLink from './ExternalLink';
import { LinksWrapper } from './styled';

interface Props {
  external?: {
    audit?: string | null;
    contract?: string | null;
  } | null;
}

const Links: FC<Props> = ({ external }) => {
  return (
    <LinksWrapper>
      <ExternalLink
        link="https://etherscan.io/address/0x249cA82617eC3DfB2589c4c17ab7EC9765350a18"
        text={external?.contract || ''}
      />
      <ExternalLink
        link="/verse-security-audit.pdf"
        text={external?.audit || ''}
      />
    </LinksWrapper>
  );
};

export default Links;

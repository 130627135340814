import React, { FC } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { LinksWrapper } from './styled';
import SocialLink from './SocialLink';

interface Props {
  social?:
    | readonly ({
        readonly title: string | null;
        readonly href: string | null;
        readonly image: {
          readonly childImageSharp: {
            readonly gatsbyImageData: IGatsbyImageData;
          } | null;
        } | null;
      } | null)[]
    | null
    | undefined;
}

const Join: FC<Props> = ({ social }) => {
  return (
    <LinksWrapper>
      {social &&
        social.map(item => (
          <SocialLink
            key={item?.title}
            title={item?.title || ''}
            image={item?.image?.childImageSharp?.gatsbyImageData}
            href={item?.href || ''}
          />
        ))}
    </LinksWrapper>
  );
};

export default Join;

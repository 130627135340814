import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const StyledImage = styled(GatsbyImage)`
  height: auto;
  width: 100%;
  aspect-ratio: 622/1250;

  ${media.sm`
    width: 100%;
    height: auto;
    aspect-ratio: 2489/1019;
  `};
`;

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { LinkContent, LinkTitle } from './styled';

interface Props {
  title: string;
  href: string;
  image?: IGatsbyImageData;
}

const SocialLink: FC<Props> = ({ title, href, image }) => {
  return (
    <LinkContent>
      <a href={href} target="_blank" rel="noreferrer">
        {image && (
          <GatsbyImage
            image={image}
            alt={`${title} Logo`}
            objectFit="contain"
            style={{ height: 64, width: 64 }}
          />
        )}
        <LinkTitle>{title}</LinkTitle>
      </a>
    </LinkContent>
  );
};

export default SocialLink;

import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { activeEnv } from '../../data/constants';

interface Props {
  locale: string;
  pathname: string;
}

const GatsbyHead: FC<Props & Queries.MetaFragment> = props => {
  const { meta, locale, pathname } = props;
  const { title, description, image, twitter } = meta || {};

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query SiteData {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <>
      <html key="html" lang={locale} />

      <title key="title">{title}</title>
      <meta key="description" name="description" content={`${description}`} />

      <meta key="og:title" property="og:title" content={`${title}`} />
      <meta
        key="og:description"
        property="og:description"
        content={`${description}`}
      />
      <meta
        key="og:image"
        property="og:image"
        content={`${siteUrl}${image?.publicURL}`}
      />
      <meta
        key="og:image_secure"
        property="og:image:secure_url"
        content={`${siteUrl}${image?.publicURL}`}
      />
      <meta key="og:image_width" property="og:image:width" content="1200" />
      <meta key="og:image_height" property="og:image:height" content="630" />

      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content={`@${twitter}`} />
      <meta key="twitter:title" name="twitter:title" content={`${title}`} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={`${description}`}
      />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={`${siteUrl}${image?.publicURL}`}
      />

      <link key="canonical" rel="canonical" href={`${siteUrl}${pathname}`} />
      {activeEnv !== 'production' && <meta name="robots" content="noindex" />}
    </>
  );
};

export const query = graphql`
  fragment Meta on Json {
    meta {
      title
      description
      image {
        publicURL
      }
      twitter
    }
  }
`;

export default GatsbyHead;

import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Section from '../../../components/Section/Section';
import { Card, CardContainer } from './styled';

const Cards: FunctionComponent<Queries.NftCardsFragment> = ({ cards }) => {
  return (
    <Section>
      <CardContainer>
        {cards &&
          cards.map(card => (
            <Card key={card?.alt}>
              {card?.icon?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={card.icon.childImageSharp?.gatsbyImageData}
                  alt={card?.alt || ''}
                  style={{ height: 78, width: 78 }}
                />
              )}
              <div>{card?.text}</div>
            </Card>
          ))}
      </CardContainer>
    </Section>
  );
};

export const query = graphql`
  fragment NftCards on Json {
    cards {
      text
      alt
      icon {
        childImageSharp {
          gatsbyImageData(height: 78, width: 78, placeholder: NONE)
        }
      }
    }
  }
`;

export default Cards;

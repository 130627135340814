import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';
import { colors } from '../../../components/Layout/colors';

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 24px;
  margin: 64px 0;

  ${media.sm`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.md`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  row-gap: 16px;
  padding: 24px;
  max-width: 312px;

  box-shadow: 0px 0px 210px rgba(0, 133, 255, 0.3);
  border: 1px solid ${colors.verseGray};
  border-radius: 16px;
`;

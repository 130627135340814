import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { m, useAnimation } from 'framer-motion';
import Section from '../../../components/Section/Section';
import Title from '../../../components/Title/Title';
import useMediaImagesSet from '../../../helpers/useMediaImagesSet';
import { StyledImage } from './styled';
import { scale } from '../../../data/animations';

const Comparison: FC<Queries.ComparisonFragment> = ({ comparison }) => {
  const images = useMediaImagesSet(comparison?.images);
  const { ref, inView } = useInView();
  const [viewed, steViewed] = useState(false);
  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start('visible');
      steViewed(true);
    } else {
      if (viewed) return;
      control.start('hidden');
    }
  }, [control, inView, viewed]);

  return (
    <Section ref={ref}>
      <Title>{comparison?.title}</Title>
      {images && (
        <m.div variants={scale} initial="hidden" animate={control}>
          <StyledImage
            image={images}
            alt={comparison?.title || ''}
            objectFit="cover"
          />
        </m.div>
      )}
    </Section>
  );
};

export const query = graphql`
  fragment Comparison on Json {
    comparison {
      title
      images {
        large {
          childImageSharp {
            gatsbyImageData
          }
        }
        medium {
          childImageSharp {
            gatsbyImageData
          }
        }
        small {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Comparison;

import styled from 'styled-components';
import { media } from '../Layout/breakpoints';

const Section = styled.section`
  box-sizing: border-box;

  width: 100%;
  margin: auto;
  padding: 64px 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 64px;
  max-width: 1440px;

  ${media.sm`
    padding: 64px 32px;
  `}

  ${media.md`
    padding: 64px;
  `}
`;

export default Section;

import styled from 'styled-components';

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: auto;
  flex-wrap: wrap;
`;

export const LinkContent = styled.div`
  padding: 16px;
  text-align: center;
`;

export const LinkTitle = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 15.4px;
  font-weight: 700;
  color: white;
  padding: 10px 0 0;
`;

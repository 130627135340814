import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { m, useAnimation } from 'framer-motion';
import Section from '../../../components/Section/Section';
import Title from '../../../components/Title/Title';
import Paragraph from '../../../components/Paragraph/Paragraph';
import { Content, Link, Text } from './styled';
import { slideInLeft, slideInRight } from '../../../data/animations';

const Burns: FC<Queries.BurnsFragment> = ({ burns }) => {
  const [viewed, steViewed] = useState(false);
  const { ref, inView } = useInView();
  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start('visible');
      steViewed(true);
    } else {
      if (viewed) return;
      control.start('hidden');
    }
  }, [control, inView, viewed]);
  return (
    <Section ref={ref}>
      <Content>
        {burns?.image?.childImageSharp?.gatsbyImageData && (
          <m.div variants={slideInLeft} initial="hidden" animate={control}>
            <GatsbyImage
              image={burns.image.childImageSharp.gatsbyImageData}
              alt={burns.title || ''}
              objectFit="contain"
            />
          </m.div>
        )}
        <Text variants={slideInRight} initial="hidden" animate={control}>
          <Title>{burns?.title}</Title>
          {burns?.description?.map((paragraph, idx) => (
            <Paragraph key={paragraph}>
              {burns?.description?.length &&
              idx === burns.description.length - 1 ? (
                <Link href={burns?.link || ''} target="_blank" rel="noreferrer">
                  {paragraph}
                </Link>
              ) : (
                paragraph
              )}
            </Paragraph>
          ))}
        </Text>
      </Content>
    </Section>
  );
};

export const query = graphql`
  fragment Burns on Json {
    burns {
      title
      description
      link
      image {
        childImageSharp {
          gatsbyImageData(width: 580, breakpoints: [768, 1024, 1440])
        }
      }
    }
  }
`;

export default Burns;

import React, { FC } from 'react';
import { Bio, MemberWrapper, Name, Position } from './styled';

interface Props {
  member?: {
    name?: string | null;
    title?: string | null;
    bio?: string | null;
  } | null;
}

const Member: FC<Props> = ({ member }) => {
  return (
    <MemberWrapper initial="hidden">
      <Name>{member?.name}</Name>
      {member?.title && <Position>{member.title}</Position>}
      <Bio>{member?.bio}</Bio>
    </MemberWrapper>
  );
};

export default Member;

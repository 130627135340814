import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Section from '../../../components/Section';

import Single from './Single';

const Products: FC<Queries.ProductsFragment> = ({ products }) => {
  return (
    <Section>
      {products?.map(product => (
        <Single key={product?.title} product={product} />
      ))}
    </Section>
  );
};

export const query = graphql`
  fragment Products on Json {
    products {
      title
      description
      link {
        text
        url
      }
      image {
        childImageSharp {
          gatsbyImageData(width: 489)
        }
      }
    }
  }
`;

export default Products;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Section from '../../../components/Section/Section';
import Title from '../../../components/Title/Title';
import useMediaImagesSet from '../../../helpers/useMediaImagesSet';
import { StyledImage } from './styled';

const Partners: FC<Queries.PartnersFragment> = ({ partners }) => {
  const images = useMediaImagesSet(partners?.images);

  return (
    <Section>
      <Title>{partners?.title}</Title>
      {images && (
        <div>
          <StyledImage
            image={images}
            alt={partners?.title || ''}
            objectFit="contain"
          />
        </div>
      )}
    </Section>
  );
};

export const query = graphql`
  fragment Partners on Json {
    partners {
      title
      images {
        small {
          childImageSharp {
            gatsbyImageData
          }
        }
        medium {
          childImageSharp {
            gatsbyImageData
          }
        }
        large {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Partners;

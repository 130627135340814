import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import { Content, StoryBody, StoryText, StoryTitle } from './styled';

const Media: FC<Queries.MediaFragment> = ({ media }) => {
  return (
    <Section>
      <Title style={{ textAlign: 'center' }}>{media?.title}</Title>
      <Content>
        {media?.publications?.map(pub => (
          <StoryBody key={pub?.title}>
            <div>
              {pub?.image?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={pub.image.childImageSharp.gatsbyImageData}
                  alt={pub.title || ''}
                  objectFit="contain"
                  style={{ height: 48, width: 'auto', flex: 0 }}
                />
              )}
            </div>
            <StoryTitle>{pub?.title}</StoryTitle>

            {pub?.content?.map(p => (
              // eslint-disable-next-line react/no-danger
              <StoryText
                dangerouslySetInnerHTML={{ __html: p || '' }}
                key={p}
              />
            ))}
          </StoryBody>
        ))}
      </Content>
    </Section>
  );
};

export const query = graphql`
  fragment Media on Json {
    media {
      title
      publications {
        title
        content
        image {
          childImageSharp {
            gatsbyImageData(height: 48)
          }
        }
      }
    }
  }
`;

export default Media;

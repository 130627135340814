import React, { FC, PropsWithChildren } from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import { GlobalStyle } from './GlobalStyle';

const LayoutRaw: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <LazyMotion features={domAnimation} strict>
        {children}
      </LazyMotion>
    </>
  );
};

export default LayoutRaw;

import styled, { css } from 'styled-components';
import { media } from '../Layout/breakpoints';
import { colors } from '../Layout/colors';

export const Container = styled.div<{ $isTop: boolean }>`
  position: fixed;
  z-index: 999999;
  width: 100%;

  transition: all 100ms ease-in;

  ${props =>
    props.$isTop
      ? css`
          background: transparent;
          box-shadow: 0px 4px 10px rgba(0, 133, 255, 0);
        `
      : css`
          background: ${colors.black};
          box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.4);
        `}
`;

export const Content = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  height: 80px;
  padding: 22px 26px;

  ${media.md`

    padding: 30px 50px;
  `}
`;

export const StyledLink = styled.a`
  color: ${colors.white};
  font-size: 14px;
  line-height: 15.4px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  ${media.md`
    line-height: 19.8px;
    font-size: 18px;
  `}
`;

import React, { FC } from 'react';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import Hero from './Hero';
import About from './About';
import Unique from './Unique';
import Products from './Products';
import Charts from './Charts';
import Burns from './Burns';
import Comparison from './Comparison';
import Media from './Media';
import Partners from './Partners';
import Team from './Team';

interface Props {
  data: Queries.HomePageQuery;
}

const View: FC<Props> = ({ data }) => {
  const { childJson } = data.page || {};

  return (
    <>
      <Navbar />

      <Hero hero={childJson?.hero || null} />
      <About about={childJson?.about || null} />
      <Unique unique={childJson?.unique || null} />
      <Products products={childJson?.products || null} />
      <Charts charts={childJson?.charts || null} />
      <Comparison comparison={childJson?.comparison || null} />
      <Burns burns={childJson?.burns || null} />
      <Media media={childJson?.media || null} />
      <Partners partners={childJson?.partners || null} />
      <Team team={childJson?.team || null} />

      <Footer />
    </>
  );
};

export default View;

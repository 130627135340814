import styled from 'styled-components';

export const StyledLink = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  color: white;
  background: #001b33;
  border-radius: 73px;
  padding: 8px 16px;

  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

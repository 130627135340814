import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Section from '../../../components/Section/Section';
import Title from '../../../components/Title/Title';
import { TeamWrapper } from './styled';
import Member from './Member';

const Team: FC<Queries.TeamFragment> = ({ team }) => {
  return (
    <Section>
      <Title>{team?.title}</Title>
      <TeamWrapper>
        {team?.list?.map((member, idx) => (
          <Member key={member?.name} member={member} idx={idx} />
        ))}
      </TeamWrapper>
    </Section>
  );
};

export const query = graphql`
  fragment Team on Json {
    team {
      title
      list {
        name
        title
        bio
      }
    }
  }
`;

export default Team;

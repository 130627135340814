import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.sm`
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: 'a0 a1' 'a0 a1';
  `}
`;

export const ChartTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  line-height: 25.232px; /* 126.159% */
  padding-bottom: 24px;
  font-size: 16px;

  ${media.md`
    font-size: 20px;
  `}
`;

import React, { FC } from 'react';
import { HeadFC, PageProps, graphql } from 'gatsby';

import GatsbyHead from '../components/GatsbyHead/GatsbyHead';

import View from '../views/Home';
import LayoutRaw from '../components/Layout/Layout';

export type PageContext = { locale: string; name: string };

const Page: FC<PageProps<Queries.HomePageQuery, PageContext>> = props => {
  const { data } = props;

  return (
    <LayoutRaw>
      <View data={data} />
    </LayoutRaw>
  );
};

export const Head: HeadFC<Queries.HomePageQuery, PageContext> = ({
  data: { page },
  pageContext,
  location,
}) => {
  return (
    <GatsbyHead
      meta={page?.childJson?.meta || null}
      pathname={location.pathname}
      locale={pageContext.locale}
    />
  );
};

export const query = graphql`
  query HomePage($name: String!) {
    page: file(name: { eq: $name }) {
      childJson {
        ...Meta
        ...Hero
        ...About
        ...Unique
        ...Products
        ...Charts
        ...Comparison
        ...Burns
        ...Media
        ...Partners
        ...Team
      }
    }
  }
`;

export default Page;

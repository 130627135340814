import React, { FC } from 'react';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import Hero from './Hero';
import Cards from './Cards';

interface Props {
  data: Queries.NftPageQuery;
}

const View: FC<Props> = ({ data }) => {
  return (
    <>
      <Navbar />

      <Hero hero={data.file?.childJson?.hero || null} />

      <Cards cards={data.file?.childJson?.cards || null} />

      <Footer />
    </>
  );
};

export default View;

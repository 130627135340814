import { Variants } from 'framer-motion';

export const slideInLeft: Variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8, ease: 'easeOut' },
  },
  hidden: { opacity: 0, x: '-100%' },
};

export const slideInRight: Variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8, ease: 'easeOut' },
  },
  hidden: { opacity: 0, x: '+100%' },
};

export const scale: Variants = {
  visible: {
    opacity: 1,
    y: 1,
    transition: { duration: 0.8, ease: 'easeOut' },
  },
  hidden: { opacity: 0, y: '100%' },
};

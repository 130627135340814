import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { m, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Section from '../../../components/Section/Section';
import Title from '../../../components/Title/Title';
import Paragraph from '../../../components/Paragraph/Paragraph';
import { Content, Text } from './styled';
import { slideInLeft, slideInRight } from '../../../data/animations';

const About: FC<Queries.AboutFragment> = ({ about }) => {
  const [viewed, steViewed] = useState(false);
  const { ref, inView } = useInView();
  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start('visible');
      steViewed(true);
    } else {
      if (viewed) return;
      control.start('hidden');
    }
  }, [control, inView, viewed]);

  return (
    <Section ref={ref}>
      <Content $inView={inView}>
        {about?.image?.childImageSharp?.gatsbyImageData && (
          <m.div variants={slideInLeft} initial="hidden" animate={control}>
            <GatsbyImage
              image={about?.image?.childImageSharp?.gatsbyImageData}
              alt={about.title || ''}
              objectFit="contain"
            />
          </m.div>
        )}
        <Text variants={slideInRight} initial="hidden" animate={control}>
          <Title style={{ textAlign: 'left' }}>{about?.title}</Title>
          {about?.description?.map(p => <Paragraph key={p}>{p}</Paragraph>)}
        </Text>
      </Content>
    </Section>
  );
};

export const query = graphql`
  fragment About on Json {
    about {
      title
      description
      image {
        childImageSharp {
          gatsbyImageData(
            width: 580
            quality: 100
            breakpoints: [768, 1024, 1440]
          )
        }
      }
    }
  }
`;

export default About;

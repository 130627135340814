import { m } from 'framer-motion';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from '../../../../components/Layout/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  position: relative;
  text-align: left;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  ${media.sm`
    grid-template-columns: 1fr 2fr;
  `}

  ${media.md`
    gap: 80px;
  `}
`;

export const Text = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 16px;

  ${media.md`
    gap: 32px;
  `}
`;

export const StyledImage = styled(GatsbyImage)`
  width: 100%;
  border-radius: 8px;
  background: #bdd3ec;

  & > img {
    height: 159px;
    width: 285px;

    ${media.sm`
    height: 256px;
    width: 325px;
  `}
    ${media.md`
    height: 304px;
    width: 489px;
  `}
  }
`;

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import Section from '../../../components/Section';
import { Text } from './styled';

const Hero: FC<Queries.NftHeroFragment> = ({ hero }) => {
  return (
    <Section>
      {hero?.image?.childImageSharp?.gatsbyImageData && (
        <GatsbyImage
          image={hero?.image?.childImageSharp?.gatsbyImageData}
          alt={hero?.title || ''}
        />
      )}

      <Text>{hero?.title}</Text>
    </Section>
  );
};

export const query = graphql`
  fragment NftHero on Json {
    hero {
      title
      image {
        childImageSharp {
          gatsbyImageData(width: 900, placeholder: NONE)
        }
      }
    }
  }
`;

export default Hero;

import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Header = styled.header`
  display: flex;
  justify-content: center;
  position: relative;

  ${media.md`
    & > :first-child::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(
        90deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 10%,
        rgba(0, 0, 0, 0) 90%,
        #000000 100%
      );
    }
  `}

  &::after {
    position: absolute;
    content: '';

    left: 0;
    right: 0;
    bottom: 0;
    height: 10%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`;

export const ButtonsWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;

  display: flex;
  padding: 64px;
  justify-content: center;

  gap: 24px;
  flex-wrap: wrap;
`;

import React, { FC } from 'react';
import reactStringReplace from 'react-string-replace';

import { Link, Text } from './styled';

interface Props {
  disclaimer?: {
    text?: string | null;
    restricted?: string | null;
    link?: string | null;
  } | null;
}

const Disclaimer: FC<Props> = ({ disclaimer: content }) => {
  const { text, restricted, link } = content || {};
  return (
    <Text>
      {reactStringReplace(text || '', '{restricted}', () => (
        <Link key="rj" href={link || ''} target="_blank" rel="noreferrer">
          {restricted}
        </Link>
      ))}
    </Text>
  );
};

export default Disclaimer;

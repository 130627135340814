import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../../components/Layout/breakpoints';
import { colors } from '../../components/Layout/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  width: 100%;
  max-width: 1440px;

  ${media.md`
    flex-direction: row;
    margin-top: 100px;
  `}
`;

export const StyledText = styled.p`
  text-align: center;
  font-size: 14px;
  width: 100%;
  max-width: 300px;

  ${media.md`
    max-width: 300px;
    font-size: 24px;
  `}

  ${media.lg`
    font-size: 32px;
    max-width: 450px;
  `}
  :last-child {
    margin-bottom: 32px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.verseBlue};

  cursor: pointer;
  display: inline-flex;
`;

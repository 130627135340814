import React, { FC } from 'react';
import { HeadFC, PageProps, graphql } from 'gatsby';

import LayoutRaw from '../components/Layout';
import GatsbyHead from '../components/GatsbyHead';

import View from '../views/404';

export type PageContext = { locale: string; name: string };

const Page: FC<PageProps<Queries.ErrorPageQuery, PageContext>> = ({ data }) => {
  return (
    <LayoutRaw>
      <View content={data.file?.childJson?.content || null} />
    </LayoutRaw>
  );
};

export default Page;

export const Head: HeadFC<Queries.ErrorPageQuery, PageContext> = ({
  data: { file },
  pageContext,
  location,
}) => {
  return (
    <GatsbyHead
      meta={file?.childJson?.meta || null}
      pathname={location.pathname}
      locale={pageContext.locale}
    />
  );
};

export const query = graphql`
  query ErrorPage($name: String!) {
    file(name: { eq: $name }) {
      childJson {
        ...Meta
        ...ErrorContent
      }
    }
  }
`;

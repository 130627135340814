import styled from 'styled-components';

const ButtonWhite = styled.a`
  padding: 12px 32px;
  min-width: 158px;
  box-sizing: border-box;

  border-radius: 12px;

  align-items: center;
  border-radius: 12px;

  background: linear-gradient(
    180deg,
    #fff 3.35%,
    rgba(255, 255, 255, 0.73) 100%
  );

  /* Card-shadow */
  box-shadow:
    0px 0.875px 5.029687404632568px 0px rgba(45, 50, 59, 0.08),
    0px 4px 14.137499809265137px 0px rgba(45, 50, 59, 0.05),
    0px 10.125px 37.92656326293945px 0px rgba(45, 50, 59, 0.03),
    0px 20px 87px 0px rgba(45, 50, 59, 0.02);

  color: #2d323b;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

export default ButtonWhite;

import React, { FC } from 'react';
import { HeadFC, PageProps, graphql } from 'gatsby';

import GatsbyHead from '../components/GatsbyHead/GatsbyHead';

import View from '../views/Nft';
import Layout from '../components/Layout';

export type PageContext = { locale: string; name: string };

const Page: FC<PageProps<Queries.NftPageQuery, PageContext>> = ({ data }) => {
  return (
    <Layout>
      <View data={data} />
    </Layout>
  );
};

export default Page;

export const Head: HeadFC<Queries.ErrorPageQuery, PageContext> = ({
  data: { file },
  pageContext,
  location,
}) => {
  return (
    <GatsbyHead
      meta={file?.childJson?.meta || null}
      pathname={location.pathname}
      locale={pageContext.locale}
    />
  );
};

export const query = graphql`
  query NftPage($name: String!) {
    file(name: { eq: $name }) {
      childJson {
        ...Meta
        ...NftHero
        ...NftCards
      }
    }
  }
`;

import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Section from '../Section';
import Title from '../Title';

import Disclaimer from './Disclaimer';
import Join from './Join';
import Links from './Links';

const Footer: FC = () => {
  const { file } = useStaticQuery<Queries.FooterContentQuery>(graphql`
    query FooterContent {
      file(name: { eq: "global.en" }) {
        childJson {
          footer {
            title
            disclaimer {
              text
              restricted
              link
            }
            social {
              title
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              href
            }
            external {
              contract
              audit
            }
          }
        }
      }
    }
  `);

  return (
    <Section as="footer">
      <Title>{file?.childJson?.footer?.title}</Title>
      <Disclaimer disclaimer={file?.childJson?.footer?.disclaimer} />

      <Join social={file?.childJson?.footer?.social} />
      <Links external={file?.childJson?.footer?.external} />
    </Section>
  );
};

export default Footer;

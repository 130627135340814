import { m } from 'framer-motion';
import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const TeamWrapper = styled.div`
  display: grid;
  gap: 32px;

  ${media.sm`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const MemberWrapper = styled(m.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25.2px; /* 157.5% */
`;

export const Position = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.05px;
  text-transform: uppercase;
`;

export const Bio = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px; /* 136% */
`;

import { createGlobalStyle } from 'styled-components';
import { media } from './breakpoints';
import { colors } from './colors';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  ___gatsby,
  ___gatsby > div {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: ${colors.white};
    background: ${colors.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  button.osano-cm-widget {
    display: none;
  }

  .osano-cm-dialog--type_box.osano-cm-dialog--position_bottom-right {
    bottom: unset;
    right: unset;
    max-width: unset;
    width: 100%;
    background: #FFFFFF;

    ${media.md`
      bottom: 1em;
      right: 1em;
      max-width: 20em;
      width: calc(100vw - 2em);
      background: rgba(255,255,255,0.7);
    `}
    .os-close-btn {
      background: white;
      border: 1px solid;
      cursor: pointer;
      padding: 8px;
      width: 100%;
      border-radius: 0.25em;
      margin-top: 12px;
      font-weight: 600;
      font-size: 15px;
    }
  }
`;

import { styled } from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Text = styled.div`
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 36px #0085ff;

  ${media.md`
     font-size: 36px;
  `}
  ${media.lg`
     font-size: 42px;
  `}
`;

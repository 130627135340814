import styled from 'styled-components';
import { media } from '../Layout/breakpoints';

const Paragraph = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 156%; /* 24.96px */
  margin: 0;
  ${media.md`
    font-size: 20px;
  `}
`;

export default Paragraph;

import { m } from 'framer-motion';
import styled from 'styled-components';
import { media } from '../../../components/Layout/breakpoints';

export const Content = styled.div`
  display: grid;
  align-items: center;
  gap: 24px;

  ${media.sm`
    grid-template-columns: 3fr 2fr;
  `}

  ${media.md`
    grid-template-columns: 1fr 580px;
  `}
`;

export const Subtitle = styled.p`
  color: #0085ff;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 30.8px */
  font-size: 16px;
  margin: 0;
  ${media.md`
    font-size: 28px;
  `}
`;

export const Text = styled(m.div)`
  order: 1;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.md`
    order: 0;
    gap: 32px;
  `}
`;

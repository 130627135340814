import styled from 'styled-components';
import { media } from '../../Layout/breakpoints';

export const Text = styled.div`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  max-width: 816px;
  margin: auto;

  ${media.sm`
    font-size: 14px;
  `}
`;

export const Link = styled.a`
  color: #0085ff;
  text-decoration-line: underline;
`;

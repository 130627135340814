import React, { FunctionComponent } from 'react';
import { StyledLink } from './styled';
import { colors } from '../../../Layout/colors';
import External from './External';

interface Props {
  link: string;
  text: string;
}

const ExternalLink: FunctionComponent<Props> = ({ link, text }) => {
  return (
    <StyledLink href={link} target="_blank" rel="noreferrer">
      {text}
      <External color={colors.verseBlue} height={12} width={12} />
    </StyledLink>
  );
};

export default ExternalLink;

import { IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import { useMemo } from 'react';

export type ImageSet = {
  large?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData;
    } | null;
  } | null;
  medium?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData;
    } | null;
  } | null;
  small?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData;
    } | null;
  } | null;
};

const useMediaImagesSet = (imageSet?: ImageSet | null) => {
  const images = useMemo(() => {
    if (
      !imageSet?.large?.childImageSharp?.gatsbyImageData ||
      !imageSet?.medium?.childImageSharp?.gatsbyImageData ||
      !imageSet?.small?.childImageSharp?.gatsbyImageData
    ) {
      return null;
    }

    return withArtDirection(imageSet.large.childImageSharp.gatsbyImageData, [
      {
        media: '(max-width: 768px)',
        image: imageSet.small.childImageSharp.gatsbyImageData,
      },
      {
        media: '(max-width: 1024px)',
        image: imageSet.medium.childImageSharp.gatsbyImageData,
      },
    ]);
  }, [imageSet]);

  return images;
};

export default useMediaImagesSet;

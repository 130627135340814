import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import reactStringReplace from 'react-string-replace';

import { StyledLink, StyledText, Wrapper } from './styled';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Section from '../../components/Section/Section';

const View: FC<Queries.ErrorContentFragment> = ({ content }) => {
  return (
    <>
      <Navbar />
      <Section>
        <Wrapper>
          {content?.image?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={content.image.childImageSharp.gatsbyImageData}
              alt={content.title || ''}
              style={{
                maxWidth: 500,
                flex: 1,
                opacity: 1,
              }}
            />
          )}
          <div>
            {content?.error_image?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage
                image={content.error_image.childImageSharp.gatsbyImageData}
                alt={content.title || ''}
              />
            )}
            <StyledText>{content?.title}</StyledText>

            <StyledText>
              {reactStringReplace(content?.subtitle || '', '{website}', () => (
                <StyledLink key="/" to="/">
                  GetVerse.com
                </StyledLink>
              ))}
            </StyledText>
          </div>
        </Wrapper>
      </Section>
      <Footer />
    </>
  );
};

export default View;

export const query = graphql`
  fragment ErrorContent on Json {
    content {
      title
      subtitle
      image {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
      error_image {
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: NONE)
        }
      }
    }
  }
`;
